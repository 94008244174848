.circleLoader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .circle {
    fill: transparent;
    stroke: #04d4fc;
    stroke-width: 5px;
    stroke-dasharray: 471;
    stroke-dashoffset: 471;
    animation: clock-animation 4s linear infinite;
    transform: rotate(-90deg);
    transform-origin: center;
    }

    .circleText {
        stroke: #04d4fc;
        fill: #04d4fc;
        font-size:60px;
        animation: text-animation 4s linear infinite;
        font-family:'Times New Roman', Times, serif
    }

@keyframes text-animation {
    0% {
        opacity: 0%;
        font-size: 60px;
    }
    
    50% {
        opacity: 100%;
        font-size: 70px;
    }
    
    
    100% {
        opacity: 0%;
        font-size: 60px;
    }
    
}

@keyframes clock-animation {
  0% {
    stroke-dashoffset: 942;
  }

  50% {
    stroke-dashoffset: 471;
  }


  100% {
    stroke-dashoffset: 0;
  }

}