.App {
    /* background-image: url("../public/img/spacePic2.jpeg");
    background-size:     cover;
    background-repeat:   no-repeat;
    background-position: center center;    */
    /* background-color: rebeccapurple; */
    height:100vh;
    overflow-x: hidden;
    /* scroll-snap-type: y mandatory; */
    scroll-behavior: smooth;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
}

/* Gets rid of scrollbar for safari and chrome */
.App::-webkit-scrollbar {
    display: none;
  }

  .personalFont {
    font-family:'Times New Roman', Times, serif
  }

  /* Styles for each page 'section' ie. home page, about page, etc. */
.section {
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    min-height: 100vh;
    height: auto;
    text-align: center;
    scroll-snap-align: center;
}

/* Entire sidebar styles */
.sidebar {
    top: 20%;
}

/* Styles for div surrouding the sidebarnav items */
.sidebarNav {
    padding: 10px;
    margin-top: 16px;
}

/* Styles for non selected sidebar nav items */
.sidebarNavItem {
    cursor: pointer;
}

/* Styles for the sidebar nav item of the page that is currently being viewed */
.sidebarNavItemSelected {
    cursor: pointer;
    font-weight: bold;
}

.sidebarNavIcon {
    cursor: pointer;
    margin-top: 20px;
    height: 30px;
}

.fade-in-image { 
    animation: fadeIn 1.5s;

}
.fade-in-text { 
    animation: fadeIn .5s;

}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

  /* Style 7
   ----------------------------- */
.section-header h1 {
    text-align: center;
    font-weight:300; letter-spacing:1px;
    font-weight: bold;
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    grid-template-rows: 27px 0;
    grid-gap: 20px;
    align-items: center;
    }
    
.section-header h1:after,.section-header h1:before {
    content: " ";
    display: block;
    border-bottom: 1px solid #04d4fc;
    height: 5px;
    background-color:#101c34;
}